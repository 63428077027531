// import queryString from 'query-string'

import './App1.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
// import freespace from './freespace.png';

import Labels from './labels';
import Footer from './components/ui/footer';
import carbonCloud from './images/carbon_cloud-app.svg';
import LineChart from './components/ui/lineChart';
import Info from './components/ui/info';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import socketClient from 'socket.io-client';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';

import carbonIcon from './images/carbon.svg';
import vocIcon from './images/voc.svg';
import humidityIcon from './images/humidity.svg';
import tempIcon from './images/temp.svg';
import Loader from './components/ui/loader';
import { string } from '@amcharts/amcharts4/core';

import ReCAPTCHA from "react-google-recaptcha";

import config from "./config/index";

// import { RiSoundcloudLine } from "react-icons/ri";
let res;

const ranges = {
  'This Week': [
    moment().utc().subtract(0, 'week').startOf('week').format('X'),
    moment().utc().subtract(1, 'day').endOf('day').format('X'),
  ],
  'Last Week': [
    moment().utc().subtract(1, 'week').startOf('week').format('X'),
    moment().utc().subtract(1, 'week').endOf('week').format('X'),
  ],
  'Last Four Weeks': [
    moment().utc().subtract(4, 'week').startOf('week').format('X'),
    moment().utc().subtract(1, 'week').endOf('week').format('X'),
  ],
  'This Month': [
    moment().utc().startOf('month').format('X'),
    moment().utc().subtract(1, 'day').endOf('day').format('X'),
  ],
  'Last Month': [
    moment().utc().subtract(1, 'month').startOf('month').format('X'),
    moment().utc().subtract(1, 'month').endOf('month').format('X'),
  ],
};

// const agent_id = {

//   2019041995: 'https://agent.electricimp.com/K8pc27BH8ITC',
//   2019041997: 'https://agent.electricimp.com/OfcgyD8u97T-',
//   2019041998: 'https://agent.electricimp.com/2C0mdpQ9jIgi',
//   2019041999: 'https://agent.electricimp.com/jUFXWhG98_B7',
//   2019042000: 'https://agent.electricimp.com/lnZwDmzfaTHf',

//   2019039317: 'https://agent.electricimp.com/2daq1DM1NHYp',
//   2019041994: 'https://agent.electricimp.com/mSCbtxxnVD0_',
//   2019041992: 'https://agent.electricimp.com/oaDZjtUDqyFM',
//   2019041993: 'https://agent.electricimp.com/5JWf6pHsu2DF',
//   2019041991: 'https://agent.electricimp.com/nDm2lsBLUzd6',
//   2019039361: 'https://agent.electricimp.com/6CaCA7_-JW1G',
//   2019039082: 'https://agent.electricimp.com/cZXf9387k4-c',
//   2019041996: 'https://agent.electricimp.com/qtuYur_c8Zyg',
//   2019039319: 'https://agent.electricimp.com/9YXvrTtv8iAy'

// }

function dataWrapper() {
  try {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);

    // Check if the qrCode query parameter exists
    if (searchParams.has('qrCode')) {
      return searchParams.get('qrCode');
    }

    // If qrCode query parameter doesn't exist, check the path parameters
    const path = url.pathname;
    const pathParams = path.split('/').filter((param) => param !== '');

    // Check if the path parameter exists
    if (pathParams.length > 0) {
      return pathParams[pathParams.length - 1];
    }

    return '';
  } catch (error) {
    console.error(error);
    return '';
  }
}

const AuthService = {

  isKeyValid() {
		const expiryTime = window.localStorage.getItem("spaceio-key-expiry");
    console.log("!!!!!",expiryTime)
		const now = Date.now();
		return Number(now) <= Number(expiryTime);
	},

	async fetchAuthKey() {
		let key = AuthService.authKey;
		if (AuthService.isKeyValid()) {
			return key
		};
		const newAuthKey = await this.refreshTokenAPI(AuthService.refreshToken);
		return newAuthKey;
	},

	get refreshToken() {
		let key = window.localStorage.getItem("x-refresh-token");
		if (key === null) {
			return null
		};
		return key;
	},

	set refreshToken(key) {
		if (!key) return;
		window.localStorage.setItem("x-refresh-token", key);
	},

	get authKey() {
		let key = window.localStorage.getItem("spaceio-key");
		if (key === null){
      return null
    }
		return key;
	},

	set authKey(key) {
		if (!key) return;
		window.localStorage.setItem("spaceio-key", key);
	},

  refreshTokenAPI: async (refreshKey) => {
		try {
			const res = await axios({
				headers: {
					"x-refresh-token": refreshKey,
				},
				method: 'post',
				url: `${config.PRODWS_URL}/api/refresh-token?requiredRefreshToken=true`
			});
			const { 'x-auth-key': newAuthKey, 'x-auth-keyexpiry': expiry, 'x-refresh-token': refreshToken } = res.headers;
			//console.log("headers", newAuthKey, expiry, refreshToken);

			AuthService.authKey = newAuthKey;
			AuthService.refreshToken = refreshToken;
      
			window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));

			return newAuthKey;
		} catch (err) {
			console.error(err);
			console.error(err);
			throw (err.response.data.error)
		}
	}
}

class App1 extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      tempUnit: false, //false == celsius AND true == fahrenheit
      loadingGraph: true,
      loading: true,
      temp: null,
      co2: null,
      voc: null,
      humid: null,
      qrCodeid: dataWrapper(),
      isDeviceId: true,
      destinations: [],
      occupancyStateColor: '#F65162',
      occupancyState: '',
      lastTHTime: 0,
      lastCo2Time: 0,
      trendLoad: false,
      key: 2,
      activeTab: 1,
      showBody: true
    };
    this.deviceId = null;
    this.timerId = null;
    this.assetID = null;
    this._getChart = this._getChart.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.dataCapture = this.dataCapture.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  state = {
    tempData: [],
    co2Data: [],
    humidData: [],
    vocData: [],
    selectedStart: moment().utc().subtract(2, 'week').startOf('day'),
    selectedEnd: moment().utc().subtract(2, 'day').endOf('day'),
  };

  async getLogin (){
    if (!this.authKey) {
      const loginApi = await axios.post(
        `${config.ACCOUNTWS_URL}/api/login?requiredRefreshToken=true`,
        {
          username: `${process.env.REACT_APP_USERNAME}`,
          password: `${process.env.REACT_APP_PASSWORD}`,
        },
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );
      var expiry = loginApi.headers['x-auth-keyexpiry'];
      var authKey = loginApi.headers['x-auth-key'];
      var refreshToken = loginApi.headers['x-refresh-token'];
      AuthService.authKey = authKey;
			AuthService.refreshToken = refreshToken;

      window.localStorage.setItem('spaceio-key-expiry', Date.now() + Number(expiry));
      return;
    }
  }

  getData = async (qrCodeid) => {
    const resForInfo = await axios.get(
      `${config.DEBUG_PAGE_URL}/label/qr.php?code=${qrCodeid}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!this.deviceId || !this.floorId) {
      this.deviceId = resForInfo.data.id;
      this.floorId = resForInfo.data.floor_id;
    }

    resForInfo.data.id = this.addDashes(resForInfo.data.id, [4, 3, 3]);
    resForInfo.data.bless_id = this.addDashesForBless_Id(
      resForInfo.data.bless_id,
      [4, 3, 3]
    );

    //console.log(resForInfo);
    this.setState({
      infoTabData: resForInfo.data,
      occupancyState:
        resForInfo.data.device_state === '1' ? 'Occupied' : 'Vacant',
      occupancyStateColor:
        resForInfo.data.device_state === '1' ? '#444' : '#444',
    });

    if (!this.assetID || !this.spaceId) {
      const assetID = await axios.get(
        `${config.PRODWS_URL}/api/devices/asset/fetch?identifier=device&id=${this.deviceId}`,
        {
          headers: {
            licenseKey: 'd819b457-4e95-4c56-a91e-950b790cd215',
            'content-type': 'application/json',
          },
        }
      );
      this.setState({
        assetID: assetID.data.assetId,
        spaceId: assetID.data.spaceId,
      });
      this.assetID = assetID.data.assetId;
      this.spaceId = assetID.data.spaceId;
    }

    res = await axios.post(
      `${config.SPOT_BASE_URL}/assets/data/12/current`,
      [`${this.assetID}`],
      {
        headers: {
          'x-location-token': `${process.env.REACT_APP_X_LOCATION_TOKEN}`,
          authorization: 'hM8bg2yFMJ37',
          'content-type': 'application/json',
        },
      }
    );

    this.setState({
      carbonDiOxide: Math.round(res.data[0].co2SCD40),
      vocIndex: parseFloat(res.data[0].vocIndexSGP40).toFixed(2),
      temperature: res.data[0].temperatureBME688.toFixed(1),
      humidity: parseFloat(res.data[0].humidityBME688).toFixed(2),
      lastUpdate: moment
        .unix(res.data[0].utcEpoch)
        .tz(resForInfo.data.timings_time_zone),
      loading: false,
    });

    if (this.state.tempUnit)
      this.setState({
        temperature:
          this.state.temperature &&
          parseFloat(((this.state.temperature * 9) / 5 + 32).toFixed(1)),
      });
    

    const resForChart = await axios.post(
      `${config.ANALYTICS_BASE_URL}/apiv2/pamc/current-data`,
      {
        floorIds: [`${this.floorId}`],
        tzTime: moment().format('YYYYMMDD'),
        slotData: true,
        assetIds: [`${this.assetID}`],
      },
      {
        headers: {
          'x-auth-key': await AuthService.fetchAuthKey(),
          'Content-Type': 'application/json',
        },
      }
    );

    if (Object.keys(resForChart.data).length !== 0) {
      if (this.state.tempUnit) {
        this.processData(resForChart.data, resForInfo.data.timings_time_zone);
        this.updateTempChart(!this.state.tempUnit);
      } else {
        this.processData(resForChart.data, resForInfo.data.timings_time_zone);
      }
    } else {
      this.setState({ loadingGraph: false });
    }

    /*var sortedData = _.sortBy(res.data, [function (o) { return o.t; }]);
    // var keys = ["co2","co2_th","th"];
    // console.log('--------keys ',keys);
    //var groupedData = _.filter(sortedData, (s) return s[keys[0]== keys[0]];
    // console.log('group by data ', groupedData);
   
    var co2_data = [];
    var temp_data = [];
    var humid_data = [];
    for (var i = 0; i < sortedData.length; i++) {
      var d = sortedData[i];
      var keys = Object.keys(d);
      //console.log(keys[1]);
      if (keys[1] === "co2") {
        //console.log('--------------co2--------------------');
        co2_data.push({
          time: moment.unix(d.t).format("MM/DD HH:mm"),
          co2: _.round(d[keys[1]])
        })
      }
      if (keys[1] === "th") {
        //console.log('--------------th--------------------');
        temp_data.push({
          time: moment.unix(d.t).format("MM/DD HH:mm"),
          temp: _.round(d[keys[1]] % 100, 2)
        })
        humid_data.push({
          time: moment.unix(d.t).format("MM/DD HH:mm"),
          humid: _.round(d[keys[1]] / 100)
        })
      }
    }
    this.setState({
      tempData: temp_data,
      humidData: humid_data,
      co2Data: co2_data
    })
    var len = humid_data.length;
    if (len > 0) {
      //var len = humid_data.length;
      var t = temp_data[len - 1].temp;
      var h = humid_data[len - 1].humid;
      var co2_len = co2_data.length;
      console.log(co2_data[co2_len - 1]);
      this.setState({
        co2: _.round(co2_data[co2_len - 1].co2),
        temp: _.round(t, 2),
        humid: _.round(h),
        time: co2_data[co2_len - 1].time
      })
    }*/

    // var res1 = await axios.get(`${agent_url}/last-state`);
    // console.log(res1);
    // this.setState({
    //   occupancyStateColor: res1.data.state === 1 ? 'Red' : '#80ed99',
    //   occupancyState: res1.data.state === 1 ? 'Occupied' : 'Vacant'
    // })
    return;
  };

  processData = (data, timeZone) => {
    let cDataArray = [];
    let hDataArray = [];
    let tDataArray = [];
    let vDataArray = [];

    let cDataObj = {};
    let hDataObj = {};
    let tDataObj = {};
    let vDataObj = {};

    let timeObj = {};
    let timeBeforeOneHour = moment().tz(timeZone).subtract(60, 'minutes');
    const currentTime = moment().tz(timeZone); //time sent by device and converted using timezone

    for (let addMinute = 0; timeBeforeOneHour <= currentTime; addMinute++) {
      let key = timeBeforeOneHour.format('HH:mm');
      timeObj[key] = null;
      timeBeforeOneHour.add(1, 'minutes');
    }

    for (const spaceValue of Object.values(data)) {
      for (const [timeKey, pamcData] of Object.entries(spaceValue)) {
        let timeKeyinFormat = moment.unix(timeKey).tz(timeZone).format('HH:mm');

        cDataObj[timeKeyinFormat] = Math.round(pamcData.co2SCD40) || null;

        hDataObj[timeKeyinFormat] = Math.round(pamcData.humidityBME688) || null;

        tDataObj[timeKeyinFormat] =
          pamcData.temperatureBME688.toFixed(1) || null;

        vDataObj[timeKeyinFormat] = Math.round(pamcData.vocIndexSGP40) || null;
      }

      for (const key of Object.keys(timeObj)) {
        cDataArray.push({
          year: key,
          value: Math.round(cDataObj[key]) || null,
        });
        hDataArray.push({
          year: key,
          value: Math.round(hDataObj[key]) || null,
        });
        tDataArray.push({
          year: key,
          value: parseFloat(tDataObj[key]) || null,
        });
        vDataArray.push({
          year: key,
          value: parseFloat(vDataObj[key]) || null,
        });
        if (key === this.state.lastUpdate.format('HH:mm')) {
          break;
        }
      }

      this.setState({
        loadingGraph: false,
        cDataArray: cDataArray,
        tDataArray: tDataArray,
        hDataArray: hDataArray,
        vDataArray: vDataArray,
      });
    }
  };

  // celsiusToFahrenheit = (data) => {
  //   for (const [spaceKey, spaceValue] of Object.entries(data)) {
  //     for (const [timeKey, pamcData] of Object.entries(spaceValue)) {
  //       pamcData.temperatureBME688 = pamcData.temperatureBME688
  //     }
  //   }
  // }

  onChange = (change) => {
    //console.log('--------------change-------------------');
    //onsole.log(change);
    // let valueMap = {};
    // _.each(change, (data, property) => {
    //     valueMap[property] = _.isArray(data) ? _.map(data, item => item.value) : data.value;
    // });
    // console.log(valueMap);
    var key = _.keys(change);
    var duration = change[key].value;
    var time = ranges[duration];
    //console.log('--------------- time : ', time);
    //this.getData(time);
  };

  onDateRangeChange = (selectedStart, selectedEnd) => {
    this.setState({
      selectedStart,
      selectedEnd,
      analysisOutputData: [],
    });
  };

  _getChart() {
    // this.getLogin();
    this.getData(this.state.qrCodeid);
    this.setState({
      loadingGraph: true,
    });
  }

  handleChange = ({ target }) => {
   // console.log(target.value);
    //console.log(target.name);
    // var name = target.name;
    this.setState({ [target.name]: target.value });
  };

  _showData() {
    // console.log('Show data called from definition');
    // clearInterval(this.timerId);
    this.setState({
      destinations: [this.state.qrCodeid],
    });
    this.dataCapture();
    // if (this.state.deviceid.length !== 0) {
    //   this.setState({ isDeviceId: false })
    // }
    // this.getData(this.state.deviceid);

    this.timerId = setInterval(this.dataCapture, 30000);
  }

  dataCapture() {
    //console.log("calling data capture")
    if (this.state.qrCodeid.length !== 0) {
      this.setState({ isDeviceId: false });
    }
    // this.connect();

    this.getData(this.state.qrCodeid);
  }

  connect() {
    //console.log('Connect definition');
    //var socket = socketClient('http://localhost:5000/', { transports: ['websocket'] });
    // var socket = socketClient('https://typedata.dev.afreespace.com/', { transports: ['websocket'] });

    // console.warn(socket);

    // socket.onAny((event, ...args) => {
    //   console.log(`got ${event}`);
    // });

    // socket.on('state', data => {
    //   console.log('------------------------ state -------------------------');
    //   var d = JSON.parse(data);
    //   var id = _.keys(d)[0];
    //   if (this.state.deviceid === id) {
    //     this.setState({
    //       occupancyStateColor: d[id].state === 1 ? 'Red' : '#80ed99',
    //       occupancyState: d[id].state === 1 ? 'Occupied' : 'Vacant'
    //     });
    //   }
    // });

    // socket.on('th', data => {

    //   var d = JSON.parse(data);

    //   var id = _.keys(d)[0];

    //   console.log(typeof ((d)));
    //   console.log(_.keys(d));
    //   console.log(typeof (this.state.deviceid));
    //   if (this.state.deviceid === id) {
    //     console.log("-------------matched id---------------------");
    //     var t = d[id].value % 100;
    //     var h = d[id].value / 100;
    //     if (d[id].utcEpoch > this.state.lastTHTime) {
    //       this.setState({
    //         temp: _.round(t, 2),
    //         humid: _.round(h),
    //         lastTHTime: d[id].utcEpoch
    //       });

    //     }

    //   }
    // });
    // socket.on('co2', data => {
    //   // console.log('------------------------ co2 -------------------------');
    //   var d = JSON.parse(data);

    //   var id = _.keys(d)[0];
    //   if (this.state.deviceid === id) {
    //     console.log("-------------matched id---------------------");
    //     if (d[id].utcEpoch > this.state.lastCo2Time) {
    //       this.setState({
    //         co2: _.round(d[id].value),
    //         lastCo2Time: d[id].utcEpoch,
    //         time: moment.unix(d[id].utcEpoch).format("MM/DD HH:mm")
    //       });
    //     }
    //   }
    // });
  }

  handleSelect(selectedTab) {
    this.setState({
      trendLoad: true,
      activeTab: selectedTab,
    });
  }

  _backToMain() {
    clearInterval(this.timerId);
    this.setState({
      isDeviceId: true,
    });
  }

  _reloadfunction() {
    this.setState({
      isDeviceId: false,
    });
    this.dataCapture();
  }

  updateTempChart = (tempUnit) => {
    if (this.state.tDataArray) {
      if (tempUnit) {
        //true means fahrenheit hence converting values to celsius
        let tDataArray = [...this.state.tDataArray];
        tDataArray.forEach(
          (obj) =>
          (obj.value =
            obj.value && parseFloat((((obj.value - 32) * 5) / 9).toFixed(1)))
        );
        this.setState({ tDataArray: tDataArray });
      } else {
        let tDataArray = [...this.state.tDataArray];
        tDataArray.forEach(
          (obj) =>
          (obj.value =
            obj.value && parseFloat(((obj.value * 9) / 5 + 32).toFixed(1)))
        );
        this.setState({ tDataArray: tDataArray });
      }
    }
  };

  updateTempUnit = (e) => {
    if (this.state.tempUnit === false) {
      //false means celsius hence converting to fahreheit
      this.setState({
        temperature:
          this.state.temperature &&
          parseFloat(((this.state.temperature * 9) / 5 + 32).toFixed(1)),
      });
      this.updateTempChart(this.state.tempUnit);
    } else {
      //converting fahrenheit to celsius
      this.setState({
        temperature:
          this.state.temperature &&
          (((this.state.temperature - 32) * 5) / 9).toFixed(1),
      });
      this.updateTempChart(this.state.tempUnit);
    }

    this.setState({ tempUnit: !this.state.tempUnit });
  };

  addDashes = (deviceId, dashPosition) => {
    return (
      deviceId.slice(0, dashPosition[0]) +
      '-' +
      deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) +
      '-' +
      deviceId.slice(
        dashPosition[0] + dashPosition[1],
        dashPosition[0] + dashPosition[1] + dashPosition[2]
      )
    );
  };

  addDashesForBless_Id = (deviceId) => {
    let deviceIdInString = null;
    console.log(deviceId);
    //console.log(typeof deviceId);
    if (typeof deviceId === 'number') {
      deviceIdInString = deviceId.toString();
    }

    // typeof (deviceId) ? Number : String

    //console.log(deviceIdInString);
    return (
      deviceIdInString.slice(0, 4) +
      '-' +
      deviceIdInString.slice(4, 7) +
      '-' +
      deviceIdInString.slice(7, 10)
    );
    // return deviceId.slice(0, dashPosition[0]) + "-" + deviceId.slice(dashPosition[0], dashPosition[0] + dashPosition[1]) + "-" + deviceId.slice(dashPosition[0] + dashPosition[1], dashPosition[0] + dashPosition[1] + dashPosition[2])
  };

  componentDidMount() {
    // console.log('window.location.search->', window.location.search);
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // const searchDeviceId = params.get('id');
    // console.log('searcgDeviceId', searchDeviceId);
    // this.setState({
    //   deviceid: searchDeviceId
    // })

    if (dataWrapper()) {
      this.getLogin();
      this._showData();
    } else {
      console.log('Error - DeviceID not found');
    }
    // console.log('DeviceId search called - end');

    // setInterval(this._showData, 30000);
    //this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  handleOnChange = (value) => {
    this.setState({ showBody: false })
   // console.log("Captcha value:", value);
  };

  render() {
    return (


      <div className='container' style={{ marginTop: '0px' }}>
        {/* {console.log("res>>>", res)} */}
        <div className='row justify-content-center' style={{ alignItems: "center", height: '100vh' }}>
          {this.state.showBody ?
            <ReCAPTCHA
              sitekey="6Lfy3R0oAAAAAILlTGByETxG9UEXdan36xS4TpP1"
              onChange={this.handleOnChange}
            /> :
            <div className='color col-lg-8' style={{ padding: '0px 0px' }}>
              {this.state.loading && <Loader />}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundColor: '#2E375B',
                }}
              >
                <div>
                  <img
                    src={carbonCloud}
                    alt='carbon-cloud'
                    style={{ padding: '13px 0' }}
                  />
                </div>
                <div>
                  <h3
                    style={{
                      textAlign: 'center',
                      color: '#38D68B',
                      fontSize: '24px',
                      fontWeight: '700',
                    }}
                  >
                    Pure Air Module
                  </h3>
                  <p
                    style={{
                      textAlign: 'center',
                      color: '#38D68B',
                      fontSize: '14px',
                      opacity: '0.8',
                      fontWeight: '400',
                    }}
                  >
                    Your air quality monitoring platform.
                  </p>
                </div>


                <div className='device-time'>
                  <div style={{ color: 'white', paddingLeft: '10px' }}>
                    <span className='device-time1'>DEVICE Name</span>
                    <br />
                    {/* {console.log(this.state.infoTabData)} */}
                    {/* <span className="device-time2">{this.state.infoTabData ? this.state.infoTabData.bless_id.replace(/(\d{4})(\d{3})(\d{3})/, "$1-$2-$3") : ""}</span> */}

                    <span className='device-time2'>
                      {this.state.infoTabData
                        ? this.state.infoTabData.bless_id
                        : ''}
                    </span>
                    {/* <span className="device-time2">{this.state.infoTabData ? this.state.infoTabData.bless_id.slice(0, 4) + "-" + this.state.infoTabData.bless_id.slice(4, 7) + "-" + this.state.infoTabData.bless_id.slice(7, 10) : ""}</span> */}

                    {/* 4,3,3 is position at which dashes are needed and total must be equal to length of string passed (4+3+3 = 10)  */}
                  </div>
                  <div style={{ color: 'white', paddingRight: '10px' }}>
                    <span className='device-time1'>TIME OF MEASUREMENT</span>
                    <br />
                    <span className='device-time2'>
                      {this.state.lastUpdate
                        ? this.state.lastUpdate.format('DD MMM HH:mm')
                        : ''}
                    </span>
                  </div>
                </div>
              </div>

              {/* {this.state.isDeviceId ?
              <div style={{ textAlign: 'center', marginTop: 50 }}>
                <h3>Please Enter Device ID</h3>
                <input
                  type="text"
                  // className="form-control"
                  id="device"
                  name="deviceid"
                  value={this.state.deviceid}
                  onChange={this.handleChange}
                  style={{ marginTop: 30, marginBottom: 10 }}></input>
                <div style={{ marginTop: 50, marginBottom: 50 }}>
                  <button type="button" className="btn btn-md btn-primary rounded" onClick={() => this._showData()}>Submit</button>
                </div>
              </div>

              : */}

              {
                <div>
                  <Tabs
                    id='uncontrolled-tab-example'
                    activeKey={this.state.activeTab}
                    onSelect={this.handleSelect}
                  >
                    <Tab eventKey={1} title='Now'>
                      {/* <div style={{ textAlign: 'right', marginRight: 10, marginBottom: 10, marginTop: 10 }}>
                      <button type="button" className="btn btn-md btn-primary rounded" onClick={() => this._reloadfunction()}>Refresh</button>
                    </div> */}
                      <Labels
                        temp={this.state.temperature}
                        co2={this.state.carbonDiOxide}
                        voc={this.state.vocIndex}
                        humid={this.state.humidity}
                        color={this.state.occupancyStateColor}
                        occupancyState={this.state.occupancyState}
                        deviceid={this.state.qrCodeid}
                        time={this.state.time}
                        selected={this.state.tempUnit}
                        toggleSelected={this.updateTempUnit}
                        loadingGraph={this.state.loadingGraph}
                      // reload={this._reloadfunction()}
                      />

                      {/* <div style={{ textAlign: 'center', marginBottom: 50 }}>
                      <button type="button" className="btn btn-md btn-primary rounded" onClick={() => this._backToMain()}>Back</button>
                    </div> */}
                      <Footer />
                    </Tab>

                    <Tab eventKey={2} title='Trends'>
                      <div className='row no-gutters'>
                        <div className='col-lg-8 col-lg-12 linecharts'>
                          {this.state.loadingGraph && <Loader />}
                          <div className='label-above-chart'>
                            <span className='span-under-icon'>
                              <img
                                src={carbonIcon}
                                alt='carbonIcon'
                                height='32px'
                                width='32px'
                              ></img>
                            </span>
                            <span style={{ color: '#2E375B', fontWeight: '700' }}>
                              CO<sub>2</sub>
                            </span>{' '}
                            &nbsp;{' '}
                            <span style={{ opacity: '0.6' }}>{' (ppm)'}</span>
                          </div>
                          <LineChart
                            dataForChart={this.state.cDataArray}
                            outsideStrokeColor={'#F65162'}
                            insideStrokeColor={'#4ECB71'}
                            lowerBound={0}
                            upperBound={1000}
                          />
                          <div className='label-above-chart'>
                            <span className='span-under-icon'>
                              <img
                                src={vocIcon}
                                alt='vocIcon'
                                height='32px'
                                width='32px'
                              ></img>
                            </span>
                            <span style={{ color: '#2E375B', fontWeight: '700' }}>
                              VOC
                            </span>{' '}
                            &nbsp;{' '}
                            <span style={{ opacity: '0.6' }}>{' (index)'}</span>
                          </div>
                          <LineChart
                            dataForChart={this.state.vDataArray}
                            outsideStrokeColor={'#F65162'}
                            insideStrokeColor={'#4ECB71'}
                            lowerBound={0}
                            upperBound={1000}
                          />
                          {/* <Co2Chart
                          chartData={this.state.co2Data} /> */}
                          <div className='label-above-chart'>
                            <span className='span-under-icon'>
                              <img
                                src={tempIcon}
                                alt='tempIcon'
                                height='32px'
                                width='32px'
                              ></img>
                            </span>
                            <span style={{ color: '#2E375B', fontWeight: '700' }}>
                              Temperature
                            </span>
                            &nbsp;{' '}
                            <span style={{ opacity: '0.6' }}>
                              {this.state.tempUnit ? '(°F)' : '(°C)'}
                            </span>
                          </div>
                          <LineChart
                            dataForChart={this.state.tDataArray}
                            outsideStrokeColor={'#F65162'}
                            insideStrokeColor={'#4ECB71'}
                            lowerBound={18}
                            upperBound={25}
                            temperature={true}
                          />
                          <div className='label-above-chart'>
                            <span className='span-under-icon'>
                              <img
                                src={humidityIcon}
                                alt='humidityIcon'
                                height='32px'
                                width='32px'
                              ></img>
                            </span>
                            <span style={{ color: '#2E375B', fontWeight: '700' }}>
                              Humidity
                            </span>{' '}
                            &nbsp;{' '}
                            <span style={{ opacity: '0.6' }}>{'(RH%)'} </span>
                          </div>
                          <LineChart
                            dataForChart={this.state.hDataArray}
                            outsideStrokeColor={'#F65162'}
                            insideStrokeColor={'#4ECB71'}
                            lowerBound={40}
                            upperBound={50}
                          />
                        </div>
                      </div>
                      <Footer />
                    </Tab>
                    <Tab eventKey={3} title='Info'>
                      <div className='heading'>
                        <Info
                          data={
                            this.state.infoTabData ? this.state.infoTabData : null
                          }
                          lastUpdate={
                            this.state.lastUpdate ? this.state.lastUpdate : ''
                          }
                        />
                      </div>
                      <Footer />
                    </Tab>
                  </Tabs>
                </div>
              }
            </div>}
        </div>

        <script src='https://unpkg.com/react/umd/react.production.min.js'></script>

        <script src='https://unpkg.com/react-dom/umd/react-dom.production.min.js'></script>

        <script src='https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js'></script>
      </div>
    );
  }
}

export default App1;
